import { Footer, SEO } from 'components';
import { allSportsInfo } from 'const';
import { motion } from 'framer-motion';
import { FormattedMessage, navigate, useIntl } from 'gatsby-plugin-intl';
import PageTransition from 'gatsby-v2-plugin-page-transitions';
import { AppState } from 'modules/redux-store';
import { SportActions } from 'modules/sports';
import { Sports } from 'modules/sports/models';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cta, selectsport, titles, utils } from 'style';

const SelectYourSports: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const sports = useSelector((state: AppState) => state.sports.sports);

  const buttonAnimation = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: 40 },
  };

  return (
    <PageTransition>
      <SEO
        title={intl.formatMessage({ id: 'selectYourSportsPageSeo' })}
        url="https://find-your-fit.eu/en/select-your-sports"
        urlEN="https://find-your-fit.eu/en/select-your-sports"
        urlDE="https://find-your-fit.eu/de/select-your-sports"
        urlIT="https://find-your-fit.eu/it/select-your-sports"
        urlNL="https://find-your-fit.eu/nl/select-your-sports"
        urlFR="https://find-your-fit.eu/fr/select-your-sports"
      />
      <section css={[selectsport.root, utils.wrapper]}>
        <motion.h1
          css={[titles.secondary, selectsport.title]}
          animate={{ y: [40, 0], opacity: [0, 1] }}
          transition={{ duration: 1.5, ease: [0.22, 1, 0.36, 1] }}
        >
          <FormattedMessage id="stepThree" /> <br />
          <span css={utils.fontFamilySansAlt}>
            <FormattedMessage id="selectSports" />
          </span>
        </motion.h1>

        <motion.div
          animate={{ y: [40, 0], opacity: [0, 1] }}
          transition={{ delay: 0.5, duration: 1.5, ease: [0.22, 1, 0.36, 1] }}
          css={selectsport.items}
        >
          {renderAllSports()}
        </motion.div>

        <motion.button
          animate={sports.length ? 'visible' : 'hidden'}
          variants={buttonAnimation}
          disabled={!sports || !sports.length}
          onClick={handleSubmit}
          css={[cta.button, cta.buttonHiddenByDefault]}
        >
          <FormattedMessage id="doneCta" />
        </motion.button>
      </section>
      <Footer />
    </PageTransition>
  );

  function handleSubmit() {
    if (sports) {
      return navigate('/results');
    }

    return;
  }

  function renderAllSports() {
    return allSportsInfo.map((sportInfo) => {
      const selected = sports.some((sport) => sport === sportInfo.id);

      return (
        <button
          onClick={handleSportClick}
          key={sportInfo.id}
          data-sport-id={sportInfo.id}
          css={[selectsport.button, selected ? selectsport.buttonSelected : '']}
        >
          <div>{sportInfo.iconElement}</div>
          <span css={[utils.typeMedium, utils.fontFamilySansAlt]}>
            {sportInfo.name}
          </span>
        </button>
      );
    });
  }

  /** Adds or removes sport from sport reducer. */
  function handleSportClick(event: React.MouseEvent<HTMLButtonElement>) {
    const value = event.currentTarget.dataset.sportId;

    if (sports.some((sport) => sport === value)) {
      removeSport(value as Sports);
    } else {
      addSport(value as Sports);
    }
  }

  function addSport(sport: Sports) {
    dispatch(SportActions.addSport(sport));
  }

  function removeSport(sport: Sports) {
    dispatch(SportActions.removeSport(sport));
  }
};

export default SelectYourSports;
